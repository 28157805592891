import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
    wishlistCount: number
    cartlistCount: number
} = {
    wishlistCount: 0,
    cartlistCount: 0,
};

export const cartDetailsSlice = createSlice({
    name: "cartDetails",
    initialState,
    reducers: {
        setWishlistCountState: (
            state,
            action: PayloadAction<number>
        ) => {
            state.wishlistCount = action.payload;
        },
        setCartlistCountState: (
            state,
            action: PayloadAction<number>
        ) => {
            state.cartlistCount = action.payload;
        },
    },
});

export const { setWishlistCountState, setCartlistCountState } = cartDetailsSlice.actions;

export default cartDetailsSlice.reducer;