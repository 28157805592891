import { createContext, useContext } from "react";

import { ComponentProps } from "../../../core/models/interfaces/component";
import {
  ApiOperations,
  useAPIService,
} from "../../../core/services/API.service";
import { API_URLS } from "../../../core/models/enums/url.enums";

export type createHelpAndSupportRequestPayLoad = {
  UserName: string;
  Email: string;
  Contact: string;
  IdNumber: string;
  Message: string;
};

const { REACT_APP_API_URL } = process.env;

export interface HelpAndSupportServiceOperations {
  getHelpAndSupport: Function;
  createHelpAndSupportRequest: Function;
}
const HelpAndSupportServiceContext =
  createContext<HelpAndSupportServiceOperations | null>(null);

export const HelpAndSupportServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();

  const getHelpAndSupport = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.DASHBOARD + API_URLS.GET_HELP_AND_SUPPORT
    );
  };

  const createHelpAndSupportRequest = (
    payload: createHelpAndSupportRequestPayLoad
  ) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.DASHBOARD +
        API_URLS.CREATE_HELP_AND_SUPPORT_REQUEST,
      payload
    );
  };

  const HelpAndSupportServiceOperations: HelpAndSupportServiceOperations = {
    getHelpAndSupport,
    createHelpAndSupportRequest,
  };

  return (
    <HelpAndSupportServiceContext.Provider
      value={HelpAndSupportServiceOperations}
    >
      {props.children}
    </HelpAndSupportServiceContext.Provider>
  );
};

export const useHelpAndSupportService = () => {
  return useContext(HelpAndSupportServiceContext);
};
