import { createContext, useContext } from "react";
import { ComponentProps } from "../../core/models/interfaces/component";
import { ApiOperations, useAPIService } from "../../core/services/API.service";
import { API_URLS } from "../../core/models/enums/url.enums";
import {
  CorporateUserType,
  UserDetailInitialState,
} from "../../core/models/types/userDetail.types";
import { useDispatch } from "react-redux";
import { setAuthState } from "../../../store/slice/AuthSlice";
import { QueryClient } from "@tanstack/react-query";

export type registerPayLoad = {
  FirstName: string;
  LastName: string;
  Email: string;
  Password: string;
  ContactNo: string;
  Address: string;
  IdNumber: string;
  Nationality: string;
  ClientKey: string;
  MarketingSource: string;
  UserType: string;
  //,"Role": "1"
};
export type SignupPayLoad = {
  selectedPlan: string;
  termsAndConditionIsChecked: string;
  UserDetailInitialState: UserDetailInitialState;
  otp?: string;
};
export type OTPPayLoad = {
  OTP: string;
  Email: string;
};
export type reactivatePayLoad = {
  token: string;
};
export type LoginPayLoad = {
  Email: string;
  Password: string;
};

const { REACT_APP_API_URL } = process.env;

export interface AuthServiceOperations {
  logIn: Function;
  reactivate: Function;
  saveLoginDetailsInStore: Function;
  resetPassword: Function;
  forgetPassword: Function;
  changePassword: Function;
  validateToken: Function;
  createPasswordOtp: Function;
  createPassword: Function;
  validateOtp: Function;
  getTermsAndCondition: Function;
  getPrivacypolicy: Function;
  signUp: Function;
  validateSingupForm: Function;
  sendOtp: Function;
  logout: Function;
  GetHearAboutUsList: Function;
  getCountryList: Function;
  corporateUserSignUp: Function;
  validateBusinessUserInquaryForm: Function;
}
const AuthServiceContext = createContext<AuthServiceOperations | null>(null);

export const AuthServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();
  const dispatch = useDispatch();

  const logIn = (body: LoginPayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.LOGIN,
      body
    );
  };

  /**
   * Saves the login details in the store. without isLoggedIn.
   * isLoggedIn = false as the user is not logged in need other API's.
   *
   * @param {any} loginDetails - The login details to be saved.
   * @return {void} This function does not return anything.
   */
  const saveLoginDetailsInStore = (loginDetails: any) => {
    dispatch(
      setAuthState({
        refreshToken: loginDetails.refreshToken,
        token: loginDetails.token,
        isLoggedIn: true,
        isAlreadyLoggedIn: loginDetails.isAlreadyLoggedIn,
      })
    );
  };

  const resetPassword = () => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.RESET_PASSWORD
    );
  };

  const forgetPassword = (email: string) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.FORGETPASSWORD,
      { Email: email }
    );
  };

  const changePassword = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CHANGEPASSWORD,
      body
    );
  };

  const validateToken = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.VALIDATE_TOKEN,
      body
    );
  };

  const createPasswordOtp = (email: string) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CREATE_PASSWORD_OTP,
      { Email: email }
    );
  };

  const createPassword = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CREATE_PASSWORD,
      body
    );
  };

  const signUp = (body: SignupPayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.REGISTER,
      body
    );
  };

  const corporateUserSignUp = (body: CorporateUserType) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_CLIENT +
        API_URLS.CORPORATE_USER_INQUIRY,
      body
    );
  };

  const validateSingupForm = (body: UserDetailInitialState) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.VALIDATE_SIGNUP_FORM,
      body
    );
  };

  const sendOtp = (body: string) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.SENDOTP,
      { Email: body }
    );
  };

  const getTermsAndCondition = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.DASHBOARD + API_URLS.TERMSANDCONDITION
    );
  };

  const getPrivacypolicy = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.DASHBOARD + API_URLS.PRIVACYPOLICY
    );
  };

  const validateOtp = (body: OTPPayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.VALIDATEOTP,
      body
    );
  };

  const reactivate = (body: reactivatePayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.REACTIVATE,
      body
    );
  };

  const logout = () => {
    const queryClient = new QueryClient();
    queryClient.removeQueries({ queryKey: ["KEY"] });
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.LOGOUT
    );
  };

  const GetHearAboutUsList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.GET_HEAR_ABOUT_US_LIST
    );
  };

  const getCountryList = () => {
    return APIService?.get(REACT_APP_API_URL + API_URLS.API + API_URLS.COUNTRY);
  };

  const validateBusinessUserInquaryForm = (body: UserDetailInitialState) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_CLIENT + API_URLS.VALIDATE_INQUARY,
      body
    );
  };

  const AuthServiceOperations: AuthServiceOperations = {
    logIn,
    saveLoginDetailsInStore,
    resetPassword,
    forgetPassword,
    changePassword,
    validateToken,
    createPasswordOtp,
    createPassword,
    getTermsAndCondition,
    getPrivacypolicy,
    validateOtp,
    signUp,
    validateSingupForm,
    sendOtp,
    logout,
    GetHearAboutUsList,
    getCountryList,
    reactivate,
    corporateUserSignUp,
    validateBusinessUserInquaryForm,
  };

  return (
    <AuthServiceContext.Provider value={AuthServiceOperations}>
      {props.children}
    </AuthServiceContext.Provider>
  );
};

export const useAuthService = () => {
  return useContext(AuthServiceContext);
};
