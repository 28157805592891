import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import router from "./Routes";
import i18n from "./i18next";
import { persistor, store } from "./store";

import { AuthServiceProvider } from "./modules/auth/services/Auth.services";
import { APIServiceProvider } from "./modules/core/services/API.service";

import Loader from "./modules/core/presentation/Loader";

import "./index.css";
import "./styles/antd-custom.css";
import "./styles/style.css";
import "./styles/responsive.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <Suspense fallback={<Loader styleLoadingOutlined={{ fontSize: 100 }} />}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n} defaultNS={"locale"}>
            <QueryClientProvider client={queryClient}>
            <APIServiceProvider>
              <AuthServiceProvider>
                <RouterProvider router={router} />
              </AuthServiceProvider>
            </APIServiceProvider>
            </QueryClientProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
