import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_CHECKED } from "../../modules/features/WorkforceProfile/helper/constant";
import { WorkForceTableDataType } from "../../modules/features/WorkforceProfile/Presentation/WorkForceProfile/Table/WorkforceTable";

interface WorkforceProfileTableSlice {
  visibleColumns: Partial<keyof WorkForceTableDataType>[];
}

const initialState: WorkforceProfileTableSlice = {
  visibleColumns: DEFAULT_CHECKED,
};

export const workForceProfileTableSlice = createSlice({
  name: "workForceProfileTable",
  initialState,
  reducers: {
    setWorkforceProfileTableState: (
      state,
      action: PayloadAction<WorkforceProfileTableSlice>
    ) => {
      state.visibleColumns = action.payload.visibleColumns;
    },
  },
});

export const { setWorkforceProfileTableState } =
  workForceProfileTableSlice.actions;

export default workForceProfileTableSlice.reducer;
