import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: any = {};

export const CompanyConfigSlice = createSlice({
  name: "companyConfig",
  initialState,
  reducers: {
    setCompanyConfigState: (state, action: PayloadAction<any>) => {
      state.companyConfig = action.payload;
    },
    setCountryConfigState: (state, action: PayloadAction<any>) => {
      state.country = action.payload;
    },
    setIndustryList: (state, action: PayloadAction<any>) => {
      state.industryList = action.payload;
    },
  },
});

export const { setCompanyConfigState, setCountryConfigState, setIndustryList } =
  CompanyConfigSlice.actions;

export default CompanyConfigSlice.reducer;
