import { createContext, useContext } from "react";

import { ComponentProps } from "../../../core/models/interfaces/component";
import {
  ApiOperations,
  useAPIService,
} from "../../../core/services/API.service";
import { API_URLS } from "../../../core/models/enums/url.enums";

const { REACT_APP_API_URL } = process.env;

export interface DownloadAppServiceOperations {
  getDownloadApp: Function;
}
const DownloadAppServiceContext =
  createContext<DownloadAppServiceOperations | null>(null);

export const DownloadAppServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();

  const getDownloadApp = (email: string) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.DASHBOARD + API_URLS.GET_DOWNLOADAPP + email
    );
  };

  const DownloadAppServiceOperations: DownloadAppServiceOperations = {
    getDownloadApp,
  };

  return (
    <DownloadAppServiceContext.Provider value={DownloadAppServiceOperations}>
      {props.children}
    </DownloadAppServiceContext.Provider>
  );
};

export const useDownloadAppService = () => {
  return useContext(DownloadAppServiceContext);
};
