import { createContext, useContext } from "react";

import { ComponentProps } from "../../../core/models/interfaces/component";
import {
  ApiOperations,
  useAPIService,
} from "../../../core/services/API.service";
import { API_URLS } from "../../../core/models/enums/url.enums";

const { REACT_APP_API_URL } = process.env;

export interface BillingServiceOperations {
  GetPaymentHistory: Function;
  downloadInvoice: Function;
}

const BillingServiceContext = createContext<BillingServiceOperations | null>(
  null
);

export const BillingServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();

  const GetPaymentHistory = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_PAYMENT + API_URLS.GET_PAYMENT_HISTORY
    );
  };
  const downloadInvoice = (invoiceId: number) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_PAYMENT +
        API_URLS.GET_INVOICE +
        "?paymentTransactionId=" +
        invoiceId
    );
  };

  const BillingServiceOperations: BillingServiceOperations = {
    GetPaymentHistory,
    downloadInvoice,
  };

  return (
    <BillingServiceContext.Provider value={BillingServiceOperations}>
      {props.children}
    </BillingServiceContext.Provider>
  );
};

export const useBillingService = () => {
  return useContext(BillingServiceContext);
};
