import { createContext, useCallback, useContext, useMemo } from "react";

import { ComponentProps } from "../../../core/models/interfaces/component";
import {
  ApiOperations,
  useAPIService,
} from "../../../core/services/API.service";
import { API_URLS } from "../../../core/models/enums/url.enums";

const { REACT_APP_API_URL } = process.env;

export interface AboutUsServiceOperations {
  getAboutUs: Function;
}
const AboutUsServiceContext = createContext<AboutUsServiceOperations | null>(
  null
);

export const AboutUsServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();

  const getAboutUs = useCallback(() => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.DASHBOARD + API_URLS.GET_ABOUTUS
    );
  }, [APIService]);

  const AboutUsServiceOperations: AboutUsServiceOperations = useMemo(
    () => ({
      getAboutUs,
    }),
    [getAboutUs]
  );

  return (
    <AboutUsServiceContext.Provider value={AboutUsServiceOperations}>
      {props.children}
    </AboutUsServiceContext.Provider>
  );
};

export const useAboutUsService = () => {
  return useContext(AboutUsServiceContext);
};
