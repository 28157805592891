import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthInitialState } from "../../modules/core/models/types/auth.types";

export const authInitialState: AuthInitialState = {
  refreshToken: "",
  token: "",
  isLoggedIn: false,
  isAlreadyLoggedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthInitialState>) => {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.isAlreadyLoggedIn = action.payload.isAlreadyLoggedIn;
    },
  },
});

export const { setAuthState } = authSlice.actions;

export default authSlice.reducer;
