import { createContext, useContext } from "react";

import { ComponentProps } from "../../../core/models/interfaces/component";
import {
  ApiOperations,
  useAPIService,
} from "../../../core/services/API.service";
import { API_URLS } from "../../../core/models/enums/url.enums";
import {
  UpdatePersonalDeadline,
  updateRating,
} from "../models/types/course.type";
import { COURSE_SEGEMENT_TYPE } from "../models/enums/course.enum";
import { useDispatch } from "react-redux";
import {
  setWishlistCountState,
  setCartlistCountState,
} from "../../../../store/slice/CartDetails";

const { REACT_APP_API_URL } = process.env;

export interface CourseServiceOperations {
  getAvailableCourses: Function;
  getAvailableAssessments: Function;
  getMyCourses: Function;
  getMyELearning: Function;
  getMyAssessments: Function;
  extendCourseExpiry: Function;
  abandonCourse: Function;
  updateRating: Function;
  updatePersonalDeadline: Function;
  GetCourseDetail: Function;
  saveCourse: Function;
  navigteToStartCourseHandler: Function;
  UpdateUserWishList: Function;
  getTagListFilter: Function;
  getTypeListFilter: Function;
  getLabelListFilter: Function;
  getDurationRangeFilter: Function;
  getPriceRangeFilter: Function;
  getPriceFilter: Function;
  getCourses: Function;
  getWishlistCoursesList: Function;
  getWishListCount: Function;
  getCoursesFromBundle: Function;
  downloadCertificate: Function;
  getCartDetails: Function;
  addToCart: Function;
  deleteItem: Function;
  clearCart: Function;
  getCartData: Function;
  selectCartItem: Function;
  getCartCount: Function;
  makePaymentTransaction: Function;
  saveTransactionDetail: Function;
}
const CourseServiceContext = createContext<CourseServiceOperations | null>(
  null
);

export const CourseServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();
  const dispatch = useDispatch();

  const getWishlistCoursesList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_WISHLIST +
        "?timestamp=" +
        new Date().getTime()
    );
  };

  const getWishListCount = () => {
    getWishlistCoursesList().then((response: any) => {
      if (response?.isError) {
        dispatch(setWishlistCountState(0));
      } else if (response?.result && response?.result.length > 0) {
        dispatch(setWishlistCountState(response.result.length));
      } else {
        dispatch(setWishlistCountState(0));
      }
    });
  };

  const getAvailableCourses = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_AVAILABLE_COURSES +
        "?isAssessment=false" +
        "&timestamp=" +
        new Date().getTime()
    );
  };

  const getAvailableAssessments = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_AVAILABLE_COURSES +
        "?isAssessment=true" +
        "&timestamp=" +
        new Date().getTime()
    );
  };

  const getMyCourses = (courseStatusType: string) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_MY_COURSES +
        courseStatusType +
        "&isAssessment=false" +
        "&timestamp=" +
        new Date().getTime()
    );
  };

  const getMyELearning = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_E_LEARNING +
        "?timestamp=" +
        new Date().getTime()
    );
  };

  const getMyAssessments = (courseStatusType: string) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_MY_COURSES +
        courseStatusType +
        "&isAssessment=true" +
        "&timestamp=" +
        new Date().getTime()
    );
  };
  const GetCourseDetail = (id: string) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_COURSE_DETAIL +
        id +
        "&timestamp=" +
        new Date().getTime()
    );
  };

  const UpdateUserWishList = (courseId: number, type: COURSE_SEGEMENT_TYPE) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.UPDATE_WISHLIST +
        courseId +
        "&type=" +
        type
    );
  };

  const extendCourseExpiry = (userCourseKey: string) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.EXTEND_COURSE_EXPIRY +
        userCourseKey
    );
  };

  const abandonCourse = (userCourseKey: string) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.ABANDON_COURSE +
        userCourseKey
    );
  };

  const updateRating = (body: updateRating) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.UPDATE_RATING,
      body
    );
  };

  const updatePersonalDeadline = (body: UpdatePersonalDeadline) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.UPDATE_PERSONAL_DEADLINE,
      body
    );
  };

  const saveCourse = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.ADD_USER_COURSE_LIST,
      body
    );
  };

  const navigteToStartCourseHandler = (courseId: number) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.START_COURSE_NAVIGATE_TO_MOODLE +
        courseId
    );
  };

  const getTagListFilter = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_TAG_LIST_FILTER
    );
  };

  const getTypeListFilter = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_TYPE_LIST_FILTER
    );
  };

  const getLabelListFilter = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_LEVEL_LIST_FILTER
    );
  };

  const getPriceFilter = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_PRICE_LIST_FILTER
    );
  };

  const getDurationRangeFilter = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_DURATION_RANGE_FILTER
    );
  };

  const getPriceRangeFilter = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_PRICE_RANGE_FILTER
    );
  };

  const getCourses = (queryparam: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_STORE_DATA,
      queryparam
    );
  };

  const getCoursesFromBundle = (id: any) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_COURSES_FROM_BUNDLE +
        `?courseBundleKey=${id}` +
        "&timestamp=" +
        new Date().getTime()
    );
  };

  const downloadCertificate = (courseId: number) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_CERTIFICATE +
        "?courseId=" +
        courseId
    );
  };

  // Shopping cart

  const getCartCount = () => {
    getCartDetails().then((response: any) => {
      if (response?.isError) {
        dispatch(setCartlistCountState(0));
      } else if (response?.result && response?.result.length > 0) {
        dispatch(setCartlistCountState(response.result.length));
      } else {
        dispatch(setCartlistCountState(0));
      }
    });
  };

  const getCartDetails = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_SHOPPING +
        API_URLS.GET_SHOPPING_CART +
        "?timestamp=" +
        new Date().getTime()
    );
  };
  const addToCart = (payload: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.ADD_TO_CART,
      payload
    );
  };

  const deleteItem = (id: number) => {
    return APIService?.remove(
      REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.DELETE_ITEM + id
    );
  };

  const clearCart = () => {
    return APIService?.remove(
      REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.CLEAR_CART
    );
  };

  const getCartData = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.GET_CART_DETAILS
    );
  };

  const selectCartItem = (id: string[]) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_SHOPPING + API_URLS.SELECT_CART_ITEM,
      id
    );
  };

  const makePaymentTransaction = (payload: any) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_PAYMENT +
        API_URLS.MAKE_PAYMENT_TRANSACTION,
      payload
    );
  };

  const saveTransactionDetail = (payload: any) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_PAYMENT +
        API_URLS.SAVE_TRANSECTION_DETAIL,
      payload
    );
  };

  const CourseServiceOperations: CourseServiceOperations = {
    getAvailableCourses,
    getAvailableAssessments,
    getMyCourses,
    getMyELearning,
    getMyAssessments,
    GetCourseDetail,
    saveCourse,
    abandonCourse,
    extendCourseExpiry,
    updateRating,
    updatePersonalDeadline,
    navigteToStartCourseHandler,
    UpdateUserWishList,
    getTagListFilter,
    getTypeListFilter,
    getLabelListFilter,
    getDurationRangeFilter,
    getPriceRangeFilter,
    getPriceFilter,
    getCourses,
    getWishlistCoursesList,
    getWishListCount,
    getCoursesFromBundle,
    downloadCertificate,
    getCartDetails,
    addToCart,
    deleteItem,
    clearCart,
    getCartData,
    selectCartItem,
    getCartCount,
    makePaymentTransaction,
    saveTransactionDetail,
  };

  return (
    <CourseServiceContext.Provider value={CourseServiceOperations}>
      {props.children}
    </CourseServiceContext.Provider>
  );
};

export const useCourseService = () => {
  return useContext(CourseServiceContext);
};
