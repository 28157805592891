import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import UserDetailReducer from "./slice/UserDetailSlice";
import CompanyDetailsReducer from "./slice/CompanyDetailsSlice";
import CompanyConfigReducer from "./slice/CompanyConfigSlice";
import UserSignupDetailReducer from "./slice/UserSignupDetailSlice";
import workForceProfileTableReducer from "./slice/WorkforceProfileTableSlice";
import AuthReducer from "./slice/AuthSlice";
import GlobleReducer from "./slice/GlobleSlice";
import CartDetailsReducer from "./slice/CartDetails";
import ThemeReducer from "./slice/ThemeSlice";
import DashboardBuilderReducer from "./slice/DashboardBuilder.slice";

enum STORE_KEYS {
  AUTH = "auth",
  SINGUP = "signup",
  USER_DETAIL = "userDetail",
  THEME = "theme",
}

const authPersistConfig = {
  key: STORE_KEYS.AUTH,
  storage,
};

const signupPersistConfig = {
  key: STORE_KEYS.SINGUP,
  storage,
};

const userDetailPersistConfig = {
  key: STORE_KEYS.USER_DETAIL,
  storage,
};

const themePersistConfig = {
  key: STORE_KEYS.THEME,
  storage,
};

const authPersistedReducer = persistReducer(authPersistConfig, AuthReducer);

const userDetailPersistedReducer = persistReducer(
  userDetailPersistConfig,
  UserDetailReducer
);

const userSignupDetailPersistedReducer = persistReducer(
  signupPersistConfig,
  UserSignupDetailReducer
);

const themePersistedReducer = persistReducer(themePersistConfig, ThemeReducer);

export const store = configureStore({
  reducer: {
    auth: authPersistedReducer,
    userDetail: userDetailPersistedReducer,
    globle: GlobleReducer,
    cartDetails: CartDetailsReducer,
    userSignupDetail: userSignupDetailPersistedReducer,
    workForceProfileTable: workForceProfileTableReducer,
    companyDetails: CompanyDetailsReducer,
    companyConfig: CompanyConfigReducer,
    theme: themePersistedReducer,
    dashboarBuilder: DashboardBuilderReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware(getDefaultMiddleware: any) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
