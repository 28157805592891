import { createContext, useContext } from "react";
import { ComponentProps } from "../models/interfaces/component";

import axiosHttp from "./Interceptors";

export interface ApiOperations {
  auth: Function;
  get: Function;
  post: Function;
  put: Function;
  remove: Function;
}

const APIServiceContext = createContext<ApiOperations | null>(null);

export const APIServiceProvider = (props: ComponentProps) => {
  const auth = (url: string, body: any) => {
    return axiosHttp
      .post(url, body)
      .then(function (response) {
        return response
          ? response?.data
            ? response?.data.result
            : response?.data
          : response;
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response?.data
            : error.response
          : error;
      });
  };

  const get = (url: string) => {
    return axiosHttp
      .get(url)
      .then(function (response: any) {
        return Promise.resolve(response ? response?.data : response);
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response?.data
            : error.response
          : error;
      });
  };

  const post = (url: string, body: any) => {
    return axiosHttp
      .post(url, body)
      .then(function (response) {
        return Promise.resolve(response ? response?.data : response);
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response?.data
            : error.response
          : error;
      });
  };

  const put = (url: string, body: any) => {
    return axiosHttp
      .put(url, body)
      .then(function (response) {
        return Promise.resolve(response ? response?.data : response);
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response?.data
            : error.response
          : error;
      });
  };

  const remove = (url: string, body?: any) => {
    return axiosHttp
      .delete(url, { data: body })
      .then(function (response) {
        return Promise.resolve(response ? response?.data : response);
      })
      .catch(function (error) {
        return error
          ? error.response
            ? error.response?.data
            : error.response
          : error;
      });
  };

  const ApiOperations: ApiOperations = {
    auth,
    get,
    post,
    put,
    remove,
  };

  return (
    <APIServiceContext.Provider value={ApiOperations}>
      {props.children}
    </APIServiceContext.Provider>
  );
};

export const useAPIService = () => {
  return useContext(APIServiceContext);
};
