import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TIntialState = {
  containerId: string | undefined;
};

const initialState: TIntialState = {
  containerId: undefined,
};

const DashboardBuilderSlice = createSlice({
  name: "dashboarBuilder",
  initialState,
  reducers: {
    setPortalDashboard: (
      state,
      action: PayloadAction<TIntialState["containerId"]>
    ) => {
      state.containerId = action.payload;
    },
    resetDashboardProtal: () => {
      return initialState;
    },
  },
});

export const { setPortalDashboard, resetDashboardProtal } =
  DashboardBuilderSlice.actions;

export default DashboardBuilderSlice.reducer;
