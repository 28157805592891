import i18n from "i18next";
import locale from "./locales/en/locale.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const defaultNS = "locale";
export const resources = {
  en: {
    locale,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "en",
    resources,
    ns: ["locale"],
    defaultNS,
  });
export default i18n;
