import { jwtDecode } from "jwt-decode";

export interface JwtPayload {
  Identity?: string;
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
}

export default function JWTDecode(token: any) {
  try {
    return jwtDecode<JwtPayload>(token);
  } catch (e) {
    return null;
  }
}
