import { createContext, useContext } from "react";
import { ComponentProps } from "../../../core/models/interfaces/component";
import {
  ApiOperations,
  useAPIService,
} from "../../../core/services/API.service";
import { API_URLS } from "../../../core/models/enums/url.enums";
import {
  ChangeEmailPayLoad,
  SendChangeEmailOTPPayLoad,
  UpdatePhoneNoPayLoad,
  UpdateProfilePayLoad,
  UpdateUserNamePayLoad,
  changeUserPasswordPayLoad,
  showOnCVType,
} from "../models/types/profile.types";
import { useDispatch, useSelector } from "react-redux";
import { setGlobleState } from "../../../../store/slice/GlobleSlice";
import { NOTIFICATION_CONST } from "../../../core/models/constants/core.constants";
import { setUserDetailState } from "../../../../store/slice/UserDetailSlice";
import { RootState, store } from "../../../../store";
import { TAP_USER_ROLES } from "../../../core/models/enums/tap.enum";

const { REACT_APP_API_URL } = process.env;

export interface MyProfileServiceOperations {
  getUserDetail: Function;
  getUserDocuments: Function;
  updateUserDetail: Function;
  getCountryList: Function;
  GetLanguageList: Function;
  GetDemographicList: Function;
  getDisabilityList: Function;
  GetDisabilityTypeList: Function;
  GetGenderList: Function;
  getHighSchoolList: Function;
  getHighSchoolGradeList: Function;
  getHighSchoolSubjectList: Function;
  getMyHighSchoolList: Function;
  addMySchool: Function;
  deleteMySchool: Function;
  getMyTertiaryList: Function;
  addMyTertiaryDetail: Function;
  editMyTertiaryDetail: Function;
  deleteMyTertiaryDetail: Function;
  getInstituteList: Function;
  getTypeOfInstituteList: Function;
  getTypeOfQualificationList: Function;
  getQualificationList: Function;
  getMyCompletedCourseList: Function;
  addMyCompletedCourse: Function;
  editMyCompletedCourse: Function;
  deleteMyCompletedCourse: Function;
  getTrainingProviderList: Function;
  getCourseList: Function;
  getMySkillsList: Function;
  addMySkills: Function;
  deleteMySkills: Function;
  getSkillList: Function;
  GetProfficiancyLevelList: Function;
  getMyExperienceList: Function;
  addMyExperience: Function;
  editMyExperience: Function;
  deleteMyExperience: Function;
  getCompanyNameList: Function;
  getMyReferenceList: Function;
  addMyReference: Function;
  editMyReference: Function;
  deleteMyReference: Function;
  getJobTitleList: Function;
  getEmployentTypeList: Function;
  getCompanyLocationList: Function;
  getCompanyList: Function;
  getMajorGroupDataList: Function;
  getSubMajorGroupDataList: Function;
  getMinerGroupDataList: Function;
  getUnitGroupDataList: Function;
  getOccupationDataList: Function;
  saveShowOnCV: Function;
  migrateUserData: Function;
  checkIsMigratedUser: Function;
  changeUserPassword: Function;
  changeUsername: Function;
  updatePhoneNo: Function;
  downloadUserDetail: Function;
  deactiveProfile: Function;
  SendChangeEmailOTP: Function;
  ChangeEmail: Function;
  UpdateProfile: Function;
  sendChangeRequestMail: Function;
  getOFOGroupDetail: Function;
}
const MyProfileServiceContext =
  createContext<MyProfileServiceOperations | null>(null);

export const MyProfileServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();
  const userDetails = useSelector((state: RootState) => state.userDetail);
  const dispatch = useDispatch();

  const getUser = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.USERDETAIL
    );
  };

  const checkIsMigratedUser = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.GET_MIGRATION_CHECK
    );
  };

  const checkIsMigratedUserHandler = () => {
    checkIsMigratedUser().then((response: any) => {
      if (response?.result) {
        const state = store.getState();
        const userDetail = state.userDetail;
        dispatch(
          setUserDetailState({
            ...userDetail,
            isEligible: response.result.isEligible,
          })
        );
      }
    });
  };
  const normalizeId = (id: number) => (id === 0 ? undefined : id);

  const getUserDetail = (checkMigratedUser: boolean = false) => {
    return getUser().then((response: any) => {
      if (response?.result) {
        const { result } = response;

        result.genderID = normalizeId(result.genderID);
        result.homeLanguageId = normalizeId(result.homeLanguageId);
        result.secondLanguageId = normalizeId(result.secondLanguageId);
        result.disabilityId = normalizeId(result.disabilityId);

        if (result.role === TAP_USER_ROLES.CORPORATE_USER) {
          if (!result.isAdmin && !result.isHR && !result.isEmployed) {
            result.isEmployed = true;
          }
        }

        dispatch(setUserDetailState({ ...userDetails, ...result }));
        if (checkMigratedUser) {
          checkIsMigratedUserHandler();
        }
      } else {
        dispatch(
          setGlobleState({
            error: {
              message: response?.errorMessage,
              type: NOTIFICATION_CONST.ERROR,
            },
          })
        );
      }
    });
  };

  const getUserDocuments = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_DOCUMENT_LIST + API_URLS.USERDOCUMENTS
    );
  };

  const updateUserDetail = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.UPDATEFULLPROFILE,
      body
    );
  };

  const getCountryList = () => {
    return APIService?.get(REACT_APP_API_URL + API_URLS.API + API_URLS.COUNTRY);
  };

  const GetDemographicList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.GETDEMOGRAPHICLIST
    );
  };

  const GetLanguageList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.API + API_URLS.GETLANGUAGELIST
    );
  };

  const getDisabilityList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.API + API_URLS.GETDISABILITYLIST
    );
  };

  const GetDisabilityTypeList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.API + API_URLS.GETDISABILITYTYPELIST
    );
  };

  const GetGenderList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.GET_GENDER_LIST
    );
  };

  const getMyHighSchoolList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_HIGHSCHOOL + API_URLS.MY_HIGH_SCHOOL
    );
  };

  const addMySchool = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.API + API_URLS.SAVEHIGHSCHOOL,
      body
    );
  };

  const deleteMySchool = (mySchoolId: string) => {
    return APIService?.remove(
      REACT_APP_API_URL + API_URLS.API + API_URLS.DELETE_HIGHSCHOOL + mySchoolId
    );
  };

  const getHighSchoolList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.API + API_URLS.HIGHSCHOOL
    );
  };

  const getHighSchoolGradeList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.API +
        API_URLS.HIGHSCHOOL +
        API_URLS.GRADELIST
    );
  };

  const getHighSchoolSubjectList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.API +
        API_URLS.HIGHSCHOOL +
        API_URLS.SUBJECTLIST
    );
  };

  const getMyTertiaryList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_TERTIARY +
        API_URLS.MY_TERTIART_QUALIFICATION_LIST
    );
  };

  const addMyTertiaryDetail = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_TERTIARY +
        API_URLS.ADD_TERTIART_QUALIFICATION,
      body
    );
  };

  const editMyTertiaryDetail = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_TERTIARY +
        API_URLS.EDIT_TERTIART_QUALIFICATION
    );
  };

  const deleteMyTertiaryDetail = (id: string) => {
    return APIService?.remove(
      REACT_APP_API_URL +
        API_URLS.BASE_TERTIARY +
        API_URLS.DELETE_TERTIART_QUALIFICATION +
        id
    );
  };

  const getInstituteList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_TERTIARY + API_URLS.GET_INSTITUDE_LIST
    );
  };

  const getTypeOfInstituteList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_TERTIARY +
        API_URLS.GET_TYPE_OF_INSTITUTE_LIST
    );
  };

  const getTypeOfQualificationList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_TERTIARY +
        API_URLS.GET_TYPE_OF_QUALIFICATION_LIST
    );
  };

  const getQualificationList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_TERTIARY +
        API_URLS.GET_QUALIFICATION_LIST
    );
  };

  const getMyCompletedCourseList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.GET_USER_COURSE_LIST
    );
  };

  const addMyCompletedCourse = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.ADD_USER_COURSE_LIST,
      body
    );
  };

  const editMyCompletedCourse = () => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_COURSE + API_URLS.ADD_USER_COURSE_LIST
    );
  };

  const deleteMyCompletedCourse = (id: string) => {
    return APIService?.remove(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.DELETE_USER_COURSE_LIST +
        id
    );
  };

  const getTrainingProviderList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_COURSE +
        API_URLS.GET_TRAINING_PROVIDER_LIST
    );
  };

  const getCourseList = () => {
    return APIService?.get(REACT_APP_API_URL + API_URLS.BASE_COURSE);
  };

  const getMySkillsList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.API + API_URLS.GET_USER_SKILL_LIST
    );
  };

  const addMySkills = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.API + API_URLS.SAVE_SKILL,
      body
    );
  };

  const deleteMySkills = (SKILL_KEY: string) => {
    return APIService?.remove(
      REACT_APP_API_URL + API_URLS.API + API_URLS.DELETE_SKILL + SKILL_KEY
    );
  };

  const getSkillList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.API + API_URLS.GET_SKILL_LIST
    );
  };

  const GetProfficiancyLevelList = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.API + API_URLS.GET_PROFFICIANCY_LEVEL_LIST
    );
  };

  // Work experience API

  const getMyExperienceList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_USER_WORK_EXPERIENCE_LIST
    );
  };

  const addMyExperience = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.SAVE_WORK_EXPERIENCE,
      body
    );
  };

  const editMyExperience = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.SAVE_WORK_EXPERIENCE
    );
  };

  const deleteMyExperience = (id: string) => {
    return APIService?.remove(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.DELETE_WORK_EXPERIENCE +
        id
    );
  };

  const getCompanyNameList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_USER_COMPANY_LIST
    );
  };
  const getMyReferenceList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_REFERENCE +
        API_URLS.GET_USER_REFERENCE_LIST
    );
  };

  const addMyReference = (body: any) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_REFERENCE + API_URLS.SAVE_REFERENCE,
      body
    );
  };
  const editMyReference = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_REFERENCE +
        API_URLS.SAVE_WORK_EXPERIENCE
    );
  };
  const deleteMyReference = (id: string) => {
    return APIService?.remove(
      REACT_APP_API_URL +
        API_URLS.BASE_REFERENCE +
        API_URLS.DELETE_REFERENCE +
        id
    );
  };
  const getJobTitleList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_JOB_TITLE_LIST
    );
  };

  const getEmployentTypeList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_EMPLOYMENT_TYPE
    );
  };

  const getCompanyList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_COMPANY_LIST
    );
  };

  const getCompanyLocationList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_LOCATION_LIST
    );
  };

  const getMajorGroupDataList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_GROUP_DATA_LIST +
        `?majorGroupCode=&subMajorGroupCode=&minorGroupCode=&unitGroupCode=&occupationCode=&workExperienceGroup=Major`
    );
  };

  const getSubMajorGroupDataList = (majorId: number) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_GROUP_DATA_LIST +
        `?majorGroupCode=${majorId}&subMajorGroupCode=&minorGroupCode=&unitGroupCode=&occupationCode=&workExperienceGroup=SubMajor`
    );
  };

  const getMinerGroupDataList = (majorId: number, subMajorId: number) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_GROUP_DATA_LIST +
        `?majorGroupCode=${majorId}&subMajorGroupCode=${subMajorId}&minorGroupCode=&unitGroupCode=&occupationCode=&workExperienceGroup=Minor`
    );
  };

  const getUnitGroupDataList = (
    majorId: number,
    subMajorId: number,
    minerId: number
  ) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_GROUP_DATA_LIST +
        `?majorGroupCode=${majorId}&subMajorGroupCode=${subMajorId}&minorGroupCode=${minerId}&unitGroupCode=&occupationCode=&workExperienceGroup=Unit`
    );
  };

  const getOccupationDataList = (
    majorId: number,
    subMajorId: number,
    minerId: number,
    unitId: number
  ) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_GROUP_DATA_LIST +
        `?majorGroupCode=${majorId}&subMajorGroupCode=${subMajorId}&minorGroupCode=${minerId}&unitGroupCode=${unitId}&occupationCode=&workExperienceGroup=Occupation`
    );
  };

  const saveShowOnCV = (showOnCV: showOnCVType) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.UPDATE_USER_SELECTION,
      showOnCV
    );
  };

  //

  const migrateUserData = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.MIGRATE_USER_DATA
    );
  };

  // mysetting

  const changeUsername = (body: UpdateUserNamePayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CHANGEUSERNAME,
      body
    );
  };

  const changeUserPassword = (body: changeUserPasswordPayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CHANGEUSERPASSWORD,
      body
    );
  };

  const updatePhoneNo = (body: UpdatePhoneNoPayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.UPDATEPHONENO,
      body
    );
  };

  const downloadUserDetail = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.DOWNLOADUSERDETAIL
    );
  };

  const deactiveProfile = () => {
    return APIService?.get(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.DEACTIVEPROFILE
    );
  };

  const SendChangeEmailOTP = (body: SendChangeEmailOTPPayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.SENDCHANGEEMAILOTP,
      body
    );
  };

  const ChangeEmail = (body: ChangeEmailPayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.CHANGEEMAIL,
      body
    );
  };

  const UpdateProfile = (body: UpdateProfilePayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL + API_URLS.BASE_USER + API_URLS.UPDATEPROFILE,
      body
    );
  };

  const sendChangeRequestMail = (message: string) => {
    return APIService?.post(
      REACT_APP_API_URL +
        API_URLS.BASE_USER +
        API_URLS.SEND_CHANGE_REQUEST_MAIL,
      { message: message }
    );
  };

  const getOFOGroupDetail = (id: number) => {
    return APIService?.get(
      REACT_APP_API_URL +
        API_URLS.BASE_WORK_EXEPERIENCE +
        API_URLS.GET_OFO_GROUP_DETAIL +
        id
    );
  };

  const MyProfileServiceOperations: MyProfileServiceOperations = {
    getUserDetail,
    getUserDocuments,
    updateUserDetail,
    getCountryList,
    GetLanguageList,
    GetDemographicList,
    getDisabilityList,
    GetDisabilityTypeList,
    GetGenderList,
    getHighSchoolList,
    getHighSchoolGradeList,
    getHighSchoolSubjectList,
    getMyHighSchoolList,
    addMySchool,
    deleteMySchool,
    getMyTertiaryList,
    addMyTertiaryDetail,
    editMyTertiaryDetail,
    deleteMyTertiaryDetail,
    getInstituteList,
    getTypeOfInstituteList,
    getTypeOfQualificationList,
    getQualificationList,
    getMyCompletedCourseList,
    addMyCompletedCourse,
    editMyCompletedCourse,
    deleteMyCompletedCourse,
    getMyReferenceList,
    addMyReference,
    editMyReference,
    deleteMyReference,
    getTrainingProviderList,
    getCourseList,
    getMySkillsList,
    addMySkills,
    deleteMySkills,
    getSkillList,
    GetProfficiancyLevelList,
    getMyExperienceList,
    addMyExperience,
    editMyExperience,
    deleteMyExperience,
    getCompanyNameList,
    getJobTitleList,
    getEmployentTypeList,
    getCompanyLocationList,
    getCompanyList,
    getMajorGroupDataList,
    getSubMajorGroupDataList,
    getMinerGroupDataList,
    getUnitGroupDataList,
    getOccupationDataList,
    saveShowOnCV,
    migrateUserData,
    checkIsMigratedUser,
    changeUserPassword,
    changeUsername,
    updatePhoneNo,
    downloadUserDetail,
    deactiveProfile,
    SendChangeEmailOTP,
    ChangeEmail,
    UpdateProfile,
    sendChangeRequestMail,
    getOFOGroupDetail,
  };

  return (
    <MyProfileServiceContext.Provider value={MyProfileServiceOperations}>
      {props.children}
    </MyProfileServiceContext.Provider>
  );
};

export const useMyProfileService = () => {
  return useContext(MyProfileServiceContext);
};
