import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAP_USER_ROLES } from "../../modules/core/models/enums/tap.enum";
import { UserDetailInitialState } from "../../modules/core/models/types/userDetail.types";

export const userDetailInitialState: UserDetailInitialState = {
  firstName: "",
  lastName: "",
  email: "",
  role: TAP_USER_ROLES.INDIVIDUAL_USER,
  contactNo: "",
  address: "",
  isRSA: false,
  idNumber: "",
  passportNumber: "",
  country: -1,
  clientKey: "",
  infoSource: "",
  userType: "",
  documents: [],
  aboutSelf: "",
  careerObjectives: "",
  careerVision: "",
  companyEmployed: "",
  countryId: 0,
  disabilityId: null,
  disabled: false,
  hasDrivingLicence: false,
  enabledInfoSharing: false,
  disabledProofDocument: "",
  ethnicity: null,
  facebookLink: "",
  birthdate: "",
  genderID: undefined,
  gender: "",
  homeLanguageName: "",
  homeLanguageId: undefined,
  linkedInLink: "",
  profileCompletionPercentage: 0,
  profilePicture: "",
  secondLanguageName: "",
  secondLanguageId: undefined,
  twitterLink: "",
  userId: 0,
  userName: "",
  userKey: "",
  registrationStartDate: "",
  userTypeDisplay: "",
  referenceNo: "",
  coordinates: {
    lat: -1,
    long: -1,
    altitude: 0,
  },
  isMigratedUser: false,
  isEligible: false,
  expiryDate: "",
  isAdmin: false,
  isHR: false,
  isEmployed: false,
  isSuperAdmin: false,
  employeeNumber: null,
  provisionDate: "",
  isProvisional: false,
  jobTitle: "",
};

export const userDetailSlice = createSlice({
  name: "userDetail",
  initialState: userDetailInitialState,
  reducers: {
    setUserDetailState: (
      state,
      action: PayloadAction<UserDetailInitialState>
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.contactNo = action.payload.contactNo;
      state.address = action.payload.address;
      state.isRSA = action.payload.isRSA;
      state.idNumber = action.payload.idNumber;
      state.passportNumber = action.payload.passportNumber;
      state.country = action.payload.country;
      state.clientKey = action.payload.clientKey;
      state.infoSource = action.payload.infoSource;
      state.userType = action.payload.userType;
      state.isMigratedUser = action.payload.isMigratedUser;
      state.isEligible = action.payload.isEligible;
      state.expiryDate = action.payload.expiryDate;
      state.documents = action.payload.documents;
      state.aboutSelf = action.payload.aboutSelf;
      state.careerObjectives = action.payload.careerObjectives;
      state.careerVision = action.payload.careerVision;
      state.companyEmployed = action.payload.companyEmployed;
      state.countryId = action.payload.countryId;
      state.disabilityId = action.payload.disabilityId;
      state.disabled = action.payload.disabled;
      state.hasDrivingLicence = action.payload.hasDrivingLicence;
      state.enabledInfoSharing = action.payload.enabledInfoSharing;
      state.disabledProofDocument = action.payload.disabledProofDocument;
      state.ethnicity = action.payload.ethnicity;
      state.facebookLink = action.payload.facebookLink;
      state.birthdate = action.payload.birthdate;
      state.genderID = action.payload.genderID;
      state.gender = action.payload.gender;
      state.homeLanguageName = action.payload.homeLanguageName;
      state.homeLanguageId = action.payload.homeLanguageId;
      state.linkedInLink = action.payload.linkedInLink;
      state.profileCompletionPercentage =
        action.payload.profileCompletionPercentage;
      state.profilePicture = action.payload.profilePicture;
      state.secondLanguageName = action.payload.secondLanguageName;
      state.secondLanguageId = action.payload.secondLanguageId;
      state.twitterLink = action.payload.twitterLink;
      state.userId = action.payload.userId;
      state.userName = action.payload.userName;
      state.userKey = action.payload.userKey;
      state.registrationStartDate = action.payload.registrationStartDate;
      state.userTypeDisplay = action.payload.userTypeDisplay;
      state.referenceNo = action.payload.referenceNo;
      state.coordinates = action.payload.coordinates;
      state.isAdmin = action.payload.isAdmin;
      state.isHR = action.payload.isHR;
      state.isEmployed = action.payload.isEmployed;
      state.isSuperAdmin = action.payload.isSuperAdmin;
      state.employeeNumber = action.payload.employeeNumber;
      state.provisionDate = action.payload.provisionDate;
      state.isProvisional = action.payload.isProvisional;
      state.jobTitle = action.payload.jobTitle;
    },
  },
});

export const { setUserDetailState } = userDetailSlice.actions;

export default userDetailSlice.reducer;
