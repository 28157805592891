import { createContext, useContext } from "react";
import { ComponentProps } from "../../../core/models/interfaces/component";
import {
  ApiOperations,
  useAPIService,
} from "../../../core/services/API.service";
import { API_URLS } from "../../../core/models/enums/url.enums";

export type NotificationsPayLoad = {
  page: string;
  pageSize: string;
};

export type markNotifactionAsReadPayLoad = {
  id: string;
};

const { REACT_APP_API_URL } = process.env;

export interface DashboardServiceOperations {
  getNotifications: Function;
  notificationsMarkAsRead: Function;
  getMarketingList: Function;
  getLeaderList: Function;
  getClientBroadcastList: Function;
  getClientBannerList: Function;
}
const DashboardServiceContext =
  createContext<DashboardServiceOperations | null>(null);

export const DashboardServiceProvider = (props: ComponentProps) => {
  const APIService: ApiOperations | null = useAPIService();

  const getNotifications = (body: NotificationsPayLoad) => {
    return APIService?.get(
      REACT_APP_API_URL +
      API_URLS.DASHBOARD +
      API_URLS.GETNOTIFICATIONS +
      body.page +
      API_URLS.PAGESIZE +
      body.pageSize
    );
  };

  const notificationsMarkAsRead = (body: markNotifactionAsReadPayLoad) => {
    return APIService?.post(
      REACT_APP_API_URL +
      API_URLS.DASHBOARD +
      API_URLS.MARK_NOTIFACTION_AS_READ +
      body.id
    );
  };

  const getMarketingList = (body: NotificationsPayLoad) => {
    return APIService?.get(
      REACT_APP_API_URL +
      API_URLS.DASHBOARD +
      API_URLS.GETMARKETINGLIST +
      body.page +
      API_URLS.PAGESIZE +
      body.pageSize
    );
  };

  const getLeaderList = (body: NotificationsPayLoad) => {
    return APIService?.get(
      REACT_APP_API_URL +
      API_URLS.DASHBOARD +
      API_URLS.GETLEADERLIST +
      body.page +
      API_URLS.PAGESIZE +
      body.pageSize
    );
  };


  const getClientBroadcastList = (body: NotificationsPayLoad) => {
    return APIService?.get(
      REACT_APP_API_URL +
      API_URLS.DASHBOARD +
      API_URLS.GET_CLIENT_BROADCAST_LIST +
      body.page +
      API_URLS.PAGESIZE +
      body.pageSize
    );
  };

  const getClientBannerList = () => {
    return APIService?.get(
      REACT_APP_API_URL +
      API_URLS.DASHBOARD +
      API_URLS.GET_CLIENT_BANNERLIST
    );
  };

  const DashboardServiceOperations: DashboardServiceOperations = {
    getNotifications,
    notificationsMarkAsRead,
    getMarketingList,
    getLeaderList,
    getClientBroadcastList,
    getClientBannerList
  };

  return (
    <DashboardServiceContext.Provider value={DashboardServiceOperations}>
      {props.children}
    </DashboardServiceContext.Provider>
  );
};

export const useDashboardService = () => {
  return useContext(DashboardServiceContext);
};
