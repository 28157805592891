import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAP_USER_ROLES } from "../../modules/core/models/enums/tap.enum";
import { UserSignupDetailInitialState } from "../../modules/core/models/types/userDetail.types";

export const userSignupDetailInitialState: UserSignupDetailInitialState = {
  firstName: "",
  lastName: "",
  email: "",
  role: TAP_USER_ROLES.INDIVIDUAL_USER,
  password: "",
  contactNo: "",
  address: "",
  isRSA: false,
  idNumber: "",
  passportNumber: "",
  country: undefined,
  clientKey: "",
  infoSource: "",
  userType: "",
  expiryDate: "",
  coordinates: {
    lat: 0,
    long: 0,
    altitude: 0,
  },
  termsAndConditionIsChecked: false,
  jobTitleId: undefined,
  companyTradeName: "",
  industryId: undefined,
  industry: "",
  noOfEmployees: 1,
};

export const userSignupDetailSlice = createSlice({
  name: "userSignupDetail",
  initialState: userSignupDetailInitialState,
  reducers: {
    setUserSignupDetailState: (
      state,
      action: PayloadAction<UserSignupDetailInitialState>
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.password = action.payload.password;
      state.contactNo = action.payload.contactNo;
      state.address = action.payload.address;
      state.isRSA = action.payload.isRSA;
      state.idNumber = action.payload.idNumber;
      state.passportNumber = action.payload.passportNumber;
      state.country = action.payload.country;
      state.clientKey = action.payload.clientKey;
      state.infoSource = action.payload.infoSource;
      state.userType = action.payload.userType;
      state.expiryDate = action.payload.expiryDate;
      state.coordinates = action.payload.coordinates;
      state.termsAndConditionIsChecked =
        action.payload.termsAndConditionIsChecked;
      state.jobTitleId = action.payload.jobTitleId;
      state.jobTitle = action.payload.jobTitle;
      state.companyTradeName = action.payload.companyTradeName;
      state.industryId = action.payload.industryId;
      state.industry = action.payload.industry;
      state.noOfEmployees = action.payload.noOfEmployees;
    },
  },
});

export const { setUserSignupDetailState } = userSignupDetailSlice.actions;

export default userSignupDetailSlice.reducer;
