import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { globleInitialState } from "../../modules/core/models/types/globle.types";
import { NOTIFICATION_CONST } from "../../modules/core/models/constants/core.constants";

const initialState: globleInitialState = {
  error: {
    message: "",
    type: NOTIFICATION_CONST.ERROR,
  },
  networkError: {
    message: "",
    type: NOTIFICATION_CONST.ERROR,
  },
  loading: false,
};

export const globleSlice = createSlice({
  name: "globle",
  initialState,
  reducers: {
    setGlobleState: (state, action: PayloadAction<globleInitialState>) => {
      state.error = {
        ...state.error,
        ...(action.payload.error || {
          message: "",
          type: NOTIFICATION_CONST.ERROR,
        }),
      };

      state.networkError = {
        ...state.networkError,
        ...(action.payload.networkError || {
          message: "",
          type: NOTIFICATION_CONST.ERROR,
        }),
      };

      state.loading = action?.payload?.loading || false;
    },
  },
});

export const { setGlobleState } = globleSlice.actions;

export default globleSlice.reducer;
